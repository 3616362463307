import { useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Chart } from "react-google-charts";
import iReport from '../../../interfaces/report.interface';

export const data = [
  ["City", "2010 Population"],
  ["New York City", 1],
  ["Los Angeles", 2],
  ["Chicago", 2],
  ["Houston", 2],
  ["Philadelphia", 5],
];

export const options = {
  bars: "horizontal",
  axes: {
    y: {
      0: { side: "left" },
    },
  },
};


export default function Graph({report,title}:{report:iReport, title:string[]}) {

  const [formatArea, setFormatArea] = useState<any>(null);

  useEffect(() => {

    const mapped = Object.entries(report.statistics[0].areas).map(([k,v]) =>{return [ k , v ] });
    mapped.unshift(title)

    //@ts-ignore
    setFormatArea(mapped)

  },[report]);


    return (<>
      {formatArea && 
              <Paper>
                <Chart
                  chartType="Bar"
                  width="75%"
                  height="400px"
                  data={formatArea}
                  options={options}
                />
          </Paper>
      }
      </>
  )
}

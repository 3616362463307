import { useField } from "formik";
import TextField from "@mui/material/TextField";

const TxtInputMaterial = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <>
      {meta.touched && meta.error ? (
        <label className="error">{meta.error}</label>
      ) : null}
      <TextField {...field} {...props} label={label} variant="outlined" />
    </>
  );
};

export default TxtInputMaterial;

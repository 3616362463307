import React, { useState } from "react";
import { Formik, Form } from "formik";
import TxtInput from "../Components/TxtInput";
import RadioButton from "../Components/RadioButton";
import * as Yup from "yup";
import { rutValidate } from "rut-helpers";
import TextArea from "../Components/TextArea";
import PhoneNumber from "../Components/PhoneNumber";
import Select from "../Components/Select";
import ThanksPostForm from "./ThanksPostForm";

import ChileJson from './comunas.json'
import CountriesJson from './countries.json'
import iTicket from '../interfaces/ticket.interface'
import { throws } from "assert";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Email inválido").required("Campo requerido"),
  phone: Yup.number().required("Campo requerido"),
  description: Yup.string(),
  lastname: Yup.string().required("Campo requerido"),
  requirement_type: Yup.string().required("Campo requerido"),
  name: Yup.string().required("Campo requerido"),
  rut: Yup.string().test("required", function (value: any) {
    if (this.parent.DNI == "passport") {
      return true;
    }

    return rutValidate(value)
      ? true
      : this.createError({
        message: `Rut inválido`,
        path: 'rut', // Fieldname
      });

  }),
  passport: Yup.string().test("required", function (value: any) {
    if (this.parent.DNI == "passport") {
      return value;
    } else {
      return true;
    }
  }),
});

export default function CrsForm() {
  const [sendData, setSendData] = useState(false);
  const [regionSelected, setRegionSelected] = useState(null);

  const onSubmit = async (values: any, resetForm: any) => {

    let applicant_neighborhood: any = calculateComune(values.applicant_city)

    applicant_neighborhood = values.applicant_neighborhood ? values.applicant_neighborhood : "foreign"

    const formData: any = {
      applicant_address: values.applicant_address,//DIRECIÓN
      applicant_city: values.applicant_city,//Ciudad
      applicant_country: values.country,
      applicant_email: values.email,
      applicant_full_name: values.name + " " + values.lastname,
      applicant_identification_number: values.rut
        ? values.rut
        : values.passport,
      applicant_neighborhood: applicant_neighborhood,//comuna
      applicant_phone: values.phone,
      area: 'Por definir',
      requirement_type: values.requirement_type,
    };
    if (values.description) {
      formData.description = values.description
    }

    const api =
      process.env.REACT_APP_ENV === "development"
        ? "http://localhost:3000/api/v1/"
        : process.env.REACT_APP_API;

    //make a request post
    return fetch(`${api}tickets`, {
      body: JSON.stringify(formData),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => {
        if (data.status !== 200) {
          console.error(data);
          alert('Error en el servidor, por favor contactar a un administrador, formulario no enviado')
        } else {
          resetForm();
          setSendData(true);
          setTimeout(() => {
            window.location.href = "https://www.aeropuertodepuertomontt.cl/"
            //setSendData(false);
          }, 5000);
          return 'ok'
        }
      })
      .catch((error) => {
        console.log(error);
        alert('Error en el servidor, por favor contactar a un administrador, formulario no enviado')
      });
  };


  const calculateComune = (region: string) => {

    if (region === '') {
      return <option value=''> </option>
    } else {
      const getRegion = ChileJson.regions.filter(x => x.name === region)[0].communes
      return getRegion.map(x => <option value={x.name}>{x.name}</option>)
    }
  }

  return (
    <>
      {!sendData && (
        <div id="sections">
          <section className="section--form">
            <div className="section__bg">
              <div className="bg bg-loaded"> </div>
            </div>
            <div className="section__logo">
              {" "}
              <img src="../img/icons/logo-color.png" />
            </div>
            <div style={{
              position: 'relative',
              marginTop: 110,
              marginBottom: -48,
              display: 'flex',
              justifyContent: 'center',
              color: 'white'
            }}>
              <h3> Consultas, Reclamos, Sugerencias y Felicitaciones</h3>
            </div>
            <div className="section__wrap">
              <div className="section__form">
                <Formik
                  initialValues={{
                    email: "",
                    DNI: "Rut",
                    description: "",
                    name: "",
                    requirement_type: "",
                    lastname: "",
                    passport: "",
                    rut: "",
                    phone: "",
                    applicant_address: "",
                    applicant_city: "",
                    applicant_neighborhood: "",
                    country: "Chile"
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={async (values, { resetForm }) =>
                    await onSubmit(values, resetForm)
                  }
                >
                  {(props) => (
                    <Form className="form">
                      <div className="section__content">
                        <h4 className="section__title">
                          Identificación de Usuario
                        </h4>
                        <div className="cols">
                          <div className="col-md-6">
                            <TxtInput
                              type="text"
                              name="name"
                              label="Nombre / Name"
                            />
                          </div>
                          <div className="col-md-6">
                            <TxtInput
                              type="text"
                              name="lastname"
                              label="Apellido / LastName"
                            />
                          </div>
                          <div className="col-md-6" style={{ marginBottom: 0 }}>
                            <div className="form__element">
                              <div className="form__title">
                                Rut o Pasaporte / DNI or Passport
                              </div>
                              <div className="form__radios">
                                <RadioButton
                                  name="DNI"
                                  value="Rut"
                                  label="Rut"
                                />

                                <RadioButton
                                  name="DNI"
                                  value="passport"
                                  label="Pasaporte"
                                />
                              </div>
                            </div>
                          </div>
                          {props.values.DNI == "Rut" && (
                            <div className="col-md-6">
                              <TxtInput
                                type="text"
                                name="rut"
                                label="Rut / N° ID"
                              />
                            </div>
                          )}

                          {props.values.DNI == "passport" && (
                            <div className="col-md-6">
                              <TxtInput
                                type="text"
                                name="passport"
                                label="Pasaporte / passport"
                              />
                            </div>
                          )}

                          <div className="col-md-6" style={{marginBottom:0}}>
                            <PhoneNumber
                              name="phone"
                              label="Teléfono / Phone"
                              setPhoneNumber={(name: any, value: any) => {
                                props.setFieldValue(name, value)
                              }}
                            />
                          </div>

                          <div className="col-md-6">
                            <TxtInput
                              type="text"
                              name="email"
                              label="Correo Electrónico / Email"
                            />
                          </div>

                          <div className="col-md-6" style={{zIndex:0}}>
                            <TxtInput
                              type="text"
                              name="applicant_address"
                              label="Dirección / Address"
                            />
                          </div>
                          <div className="col-md-6">
                            <Select
                              type="text"
                              name="country"
                              label="País / Country"
                            >
                              {CountriesJson.map(x => <option value={x.name}>{x.name}</option>)}
                            </Select>
                          </div>
                          {props.values.country === 'Chile' && <>
                            <div className="col-md-6" style={{zIndex:0}}>
                              <Select
                                type="text"
                                ChileJson
                                name="applicant_city"
                                label="Región / State"
                              >
                                <option value=""></option>
                                {ChileJson.regions.map(x => <option value={x.name}>{x.name}</option>)}
                              </Select>
                            </div>
                            <div className="col-md-6">
                              <Select
                                type="text"
                                name="applicant_neighborhood"
                                label="Comuna / Location"
                              >
                                {calculateComune(props.values.applicant_city)}
                              </Select>
                            </div></>}
                        </div>
                      </div>
                      <div className="section__content">
                        <h4 className="section__title">
                          Requerimiento Usuario / User Requirement
                        </h4>
                        <div className="cols">
                          <div className="col-md-12">
                            <div className="form__element">
                              <div className="form__title">
                                Tipificación / Typification
                              </div>
                              <div className="form__radios">
                                <RadioButton
                                  name="requirement_type"
                                  value="query"
                                  label="Consulta / Question"
                                />

                                <RadioButton
                                  name="requirement_type"
                                  value="claim"
                                  label="Reclamo / Claim"
                                />

                                <RadioButton
                                  name="requirement_type"
                                  value="congrats"
                                  label="Felicitaciones / Congrats"
                                />

                                <RadioButton
                                  name="requirement_type"
                                  value="suggestion"
                                  label="Sugerencia / Suggestion"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <TextArea
                              type="text"
                              name="description"
                              label="Descripción/ Description"
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="form__buttons">
                              <button disabled={props.isSubmitting} type="submit" className="btn btn--small">
                                {props.isSubmitting ? 'Enviando..' : 'Enviar solicitud'}
                              </button>
                              <a
                                href="index.html"
                                className="btn btn--small btn--gray"
                              >
                                Cancelar
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </div>
      )}
      {sendData && <ThanksPostForm />}
    </>
  );
}

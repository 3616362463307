import { useField } from 'formik'

const Select = ({ label, ...props }: any) => {
  const [field, meta] = useField(props)
  return (
    <div className="form__element">
        <div className="form__title">  {label}</div>
        <div className="form__input">
      <select {...field} {...props} />
      </div>
      {meta.touched && meta.error 
        ? <div>{meta.error}</div> : null}
    </div>
  )
}

export default Select

import {useEffect} from 'react'
import { useParams } from "react-router-dom";

export default function ConfirmResponsability() {

  const url:any = useParams();
  useEffect(() => {
    if(url.id){
    const options = {method: 'GET'}
    const baseURL =
    process.env.REACT_APP_ENV === 'development'
        ? 'http://localhost:3000/api/v1/'
        : process.env.REACT_APP_API;

    fetch(`${baseURL}tickets/${url.id}/attend `,options);
    }
  },[url])
  

  return (
    <div id="sections">
    <section className="section--form">
      <div className="section__logo"><img src="img/icons/logo-color.png" /></div>
      <div className="section__bg"> 
        <div className="bg"> <img src="img/uploads/1.jpg"/></div>
      </div>
      <div className="section__wrap">
        <div className="section__form section__form--thanks"> 
          <div className="section__icon"><img src="img/icons/send.svg"/></div>
          <h2 className="section__title">Muchas gracias</h2>
          <p className="section __description">Informaremos al interesado que el ticket queda en sus manos</p>    
        </div>
      </div>
    </section>
  </div>
  )
}
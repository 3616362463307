import { useState } from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import DateForm from '../DateForm';
import GraphCrs from './GraphCrs';
import iReport from '../../../interfaces/report.interface';
import axios from '../../../utils/axios';
import useMsgFetch from '../../../hooks/useMsgFetch';

export default function CRSReport() {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [report, setReport] = useState<iReport | null>(null);
  const [downloading, setDownloading] = useState(false);

  const statusApi = useMsgFetch((query: string) =>
    axios.get(query).then((response: any) => response)
  );

  const downloadPdf = () => {
    if (!startDate || !endDate) {
      alert('Completar campo fecha');
      return false;
    }
    setDownloading(true);
    axios
      .get('/reports/ticket_report', {
        params: {
          range: [startDate, endDate],
          type: 'pdf',
        },
        timeout: 300000,
      })
      .then((res) => {
        let api =
          process.env.REACT_APP_ENV === 'development'
            ? 'http://localhost:3000'
            : process.env.REACT_APP_API;
        if (api) {
          api = api.replace('/api/v1/', '');
          const url = `${api}${res.data.file}`;
          window.open(url);
        } else {
          console.error('api no existe');
        }
      })
      .catch((err) => {
        console.log("Error", err);
        alert(
          'Ocurrió un error al procesar la solicitud: Asegúrese de que existan registros en el rango de fecha seleccionado'
        );
      }).finally(() => setDownloading(false));
  };

  const onSubmit = async () => {
    if (!startDate || !endDate) {
      alert('Completar campo fecha');
      return false;
    }

    let dataStartDate = '';
    let dataEndDate = '';
    if (startDate?.getMonth() && endDate?.getMonth()) {
      dataStartDate = `start_date=${startDate?.getFullYear()}/${
        startDate?.getMonth() + 1
      }/${startDate?.getDate()}&`;
      dataEndDate = `end_date=${endDate?.getFullYear()}/${
        endDate?.getMonth() + 1
      }/${endDate?.getDate()}&`;
    }
    const query =
      `/tickets/statistics?` +
      dataStartDate +
      dataEndDate +
      `by_requirement_type=''`;

    const result = await statusApi.run(query);

    if (Object.keys(result.statistics[0].requirement_types).length === 0) {
      alert('No hay registros en estas fechas');
      setReport(null);
    } else {
      setReport(result);
    }
  };

  return (
    <div style={{ marginTop: 50 }}>
      <Box sx={{ minWidth: 120 }}>
        <h1>Reporte Total CRS</h1>

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <FormControl>
            <FormLabel id='demo-controlled-radio-buttons-group'>
              Periodo de análisis
            </FormLabel>
            <div style={{ marginTop: 20, marginBottom: 20, display: 'flex' }}>
              <DateForm
                txt={'Fecha Inicio'}
                startDate={startDate}
                setStartDate={setStartDate}
              />
              <DateForm
                txt={'Fecha Final'}
                startDate={endDate}
                setStartDate={setEndDate}
              />
            </div>
            <button
              className='btn btn--small noprint'
              onClick={() => downloadPdf()}
              disabled={downloading}
            >
              {' '}
              {downloading ? 'Cargando ...' : 'Descargar PDF '}
            </button>
          </FormControl>
        </div>
        <br></br>
        <button className='btn btn--small noprint' onClick={() => onSubmit()}>
          {' '}
          {statusApi.loading ? 'Cargando' : 'Buscar '}
        </button>
      </Box>
      {report && <GraphCrs report={report} title={['CRS', 'Tickets']} />}
    </div>
  );
}

import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import Chip from "@mui/material/Chip";

interface StateTickets {
  canceled: number;
  closed: number;//Cerrados
  completed: number;//Completado
  delayed: number;//Atrasados
  in_process: number;//En proceso
  pending: number;//Por procesar
  revision:number;//Entregado
  not_apply:number;
  reopen: number;
}

export default function StateTickets() {
  const [stateTickets, setStateTickets] = useState<StateTickets>();

  useEffect(() => {
    axios
      .get("/tickets/stats")
      .then((response) => {
        setStateTickets(response.data.tickets);
      })
      .catch((error) => {});
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        marginBottom: 26,
      }}
    >
      <Chip
        label={"Por procesar: " + (stateTickets?.pending || 0)}
        color="primary"
        variant="outlined"
      />
      <Chip
        label={"En proceso: " + (stateTickets?.in_process || 0)}
        color="primary"
        variant="outlined"
      />
           <Chip
        label={"Entregado: " + (stateTickets?.revision || 0)}
        color="primary"
        variant="outlined"
      />
      <Chip
        label={"Completados: " + (stateTickets?.completed || 0)}
        color="primary"
        variant="outlined"
      />
      <Chip
        label={"Cerrados: " + (stateTickets?.closed || 0)}
        color="primary"
        variant="outlined"
      />
      <Chip
        label={"Atrasados: " + (stateTickets?.delayed || 0 )}
        color="primary"
        variant="outlined"
      />
    </div>
  );
}

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect} from 'react';
import { ObjectEncodingOptions } from 'fs';
import { number } from 'yup';

export default function SelectAutocomplete({
   initialVal,
   getResult,
   options,
   label}:any) {
 
  const [value, setValue] = React.useState<any | null>(initialVal);
  const [inputValue, setInputValue] = React.useState<any>(initialVal);
  
  useEffect(() => {
    if (value) {
      getResult(value);
    }
  }, [value]);
  return (
    <div>
      <Autocomplete
        value={value}
        onChange={(event: any, newValue: string | null) => {
            setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </div>
  );
}

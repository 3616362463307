import React from 'react'

export default function ThanksPostForm() {
  return (
    <div id="sections">
    <section className="section--form">
      <div className="section__logo"> <img src="img/icons/logo-color.png"/></div>
      <div className="section__bg"> 
        <div className="bg"> <img src="img/uploads/1.jpg"/></div>
      </div>
      <div className="section__wrap">
        <div className="section__form section__form--thanks"> 
          <div className="section__icon"><img src="img/icons/send.svg"/></div>
          <h2 className="section__title">Tu Solicitud se envió correctamente</h2>
          <p className="section __description">Esta se derivará al área correspondiente. Llegará un correo de confirmación a su email.</p>    
        </div>
      </div>
    </section>
  </div>

  )
}

import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Formik, Form } from "formik";
import TxtInputMaterial from "../../Components/TxtInputMaterial";
import SelectAutocomplete from "../../Components/SelectAutocomplete";
import * as Yup from "yup";
import axios from "../../utils/axios";

const UserSchema = Yup.object().shape({
  email: Yup.string().email("Email inválido").required("Campo requerido"),
  first_name: Yup.string().required("Campo requerido"),
  last_name: Yup.string().required("Campo requerido"),
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const userInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  id: "",
};

export default function ModalUser({
  executeReload,
  user,
}: {
  executeReload: Function;
  user: any;
}) {
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState("");
  const [userSelected, setUserSelected] = useState(userInitialValues);
  const handleOpen = () => {
    setUserSelected(userInitialValues);
    setRole("");
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (user.email) {
      setRole(user.role);
      setOpen(true);
      setUserSelected(user);
    }
  }, [user]);

  const onSubmit = (values: any, resetForm: any) => {
    //axios post user
    //["admin", "assistant", "supervisor", "officer"]
    //["Administrador", "Asistente", "Supervisor", "officer"]}
    const roles = [
      ["Administrador", "admin"],
      ["Operario", "supervisor"],
    ];
    
    if (role === "") {
      alert("Debe seleccionar un role");
      return;
    }
    values.role = roles.filter((items) => items[0] === role)[0][1];
    if (userSelected.email !== "") {
      const editUser = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        role: values.role,
      };
      axios.put(`/users/${userSelected.id}`, editUser)
      .then((res) => {
        alert("Usuario actualizado con éxito");
        executeReload();
        setOpen(false);
      })
      .catch((error) => {
        alert("ha ocurrido un error. Intente nuevamente");
        console.log(error);
      });
    } else {
      axios
        .post("/users", values)
        .then(function (response) {
          alert("Usuario creado con éxito");
          resetForm();
          setRole("");
          executeReload();
        })
        .catch(function (error) {
          alert("ha ocurrido un error. Intente nuevamente");
          console.log(error);
        });
    }

  };

  return (
    <div>
      <Button onClick={handleOpen}>Crear usuario</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Crear Usuario
          </Typography>
          <Formik
            initialValues={userSelected}
            validationSchema={UserSchema}
            onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
          >
            {({ isSubmitting }) => (
              <Form className="form">
                <div className="row">
                  <TxtInputMaterial
                    type="text"
                    name="first_name"
                    label="Primer Nombre"
                  />
                </div>
                <div className="row">
                  <TxtInputMaterial
                    type="text"
                    name="last_name"
                    label="Apellido"
                  />
                </div>
                <div className="row">
                  <TxtInputMaterial type="text" name="email" label="Correo" />
                </div>
                <div className="row">
                  <SelectAutocomplete
                    options={[
                      "Administrador",
                      "Operario",
                    ]}
                    label={"Role"}
                    initialVal={role}
                    getResult={(val: string) => {
                      setRole(val);
                    }}
                  />
                </div>
                <div className="buttonsModal">
                <button type="submit" className="btn btn--small" disabled={isSubmitting}>
                  {isSubmitting ? "Cargando..." : "Enviar"}
                </button>

                <button  onClick={() =>handleClose()} className="btn btn--small btn--colorsecondary">
                  Cerrar
                </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

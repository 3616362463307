import axios from 'axios';

const instance = axios.create({
    baseURL:
        process.env.REACT_APP_ENV === 'development'
            ? 'http://localhost:3000/api/v1/'
            : process.env.REACT_APP_API,
    timeout: 60 * 1000
});

// Add a request interceptor
instance.interceptors.request.use(
    function (config:any) {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            config.headers["Authorization"] = accessToken;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data because it is considered a global script file. Add an import, export, or an empty 'export {}' statement to make it a module.  TS1208
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        let refreshToken = localStorage.getItem("refreshToken");
        if (
            refreshToken &&
            error.response.status === 401 &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;
           const baseurl =  process.env.REACT_APP_ENV === 'development'
                ? 'http://localhost:3000/'
                : process.env.REACT_APP_API
            return axios
                .post(`${baseurl}auth/refreshMyTokens`, { refreshToken: refreshToken })
                .then((res) => {
                    if (res.status === 200) {
                        localStorage.setItem("accessToken", res.data.access_token);
                        axios.defaults.headers.common['Authorization'] = res.data.access_token;
                        axios.defaults.headers.common.Authorization = res.data.access_token;
                        return axios(originalRequest);
                    }
                });
        }
        return Promise.reject(error);
    }
);



export default instance;
import { useField } from "formik";

const TxtInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <div className="form__element">
      <div className="form__title">{label}      {meta.touched && meta.error ? (
          <label className="error">{meta.error}</label>
        ) : null} </div>
      <div className="form__input">
        <input type="text" {...field} {...props} />
      
      </div>
    </div>
  );
};

export default TxtInput;

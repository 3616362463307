import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DateForm({txt, startDate, setStartDate}:{txt:string,startDate:any, setStartDate:any}) {
  return (
    <div 
    style={{ marginLeft:10}}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={txt}
        value={startDate}
        onChange={(newValue:any) => {
            setStartDate(newValue);
        }}
        renderInput={(params:any) => <TextField {...params} />}
      />
    </LocalizationProvider>
    </div>
  );
}
import {useReducer} from 'react'

const useMsgFetch = (fn:any) => {
  const initialState = { loading: false, error: null, value: null };
  const stateReducer = (_:any, action:any) => {
    switch (action.type) {
      case 'start':
        return { loading: true, error: null, value: null };
      case 'finish':
        return { loading: false, error: null, value: action.value };
      case 'error':
        return { loading: false, error: action.error, value: null };
      default:
        return  { loading: false, error: 'no existe el action type', value: null };
    }
  };

  const [state, dispatch] = useReducer(stateReducer, initialState);

  const run = async (args ="") => {

    try {
      dispatch({ type: 'start' });
      const value = await fn(args);
      if(value.status === 200){
        dispatch({ type: 'finish', value });
        return value.data;
      }else{
        dispatch({ type: 'error', error: 'status'+value.status });
      }
    } catch (error) {
      dispatch({ type: 'error', error });
    }
  };

  return { ...state, run };
};

export default useMsgFetch
import { useCallback } from "react";
import { GridActionsCellItem, GridRowId } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import Table from "../../Components/Table";
import createColumn from "../../utils/createColumn";
import { CreateColumn } from "../../interfaces/columns.interface";
import { useHistory, Redirect } from "react-router-dom";
import { getGridSingleSelectOperators } from "@mui/x-data-grid";

import DownloadExcel from "./DownloadExcel";
import StateTickets from "./StateTickets";

export default function Panel() {
  const history = useHistory();

  const editUser = useCallback(
    (id: GridRowId) => () => {
      history.push("/panel/" + id);
    },
    []
  );

  const columns: CreateColumn = createColumn();
  columns.add("ticket_number", "Número Seguimiento");
  columns.add("requirement_type", "Tipo");
  columns.add("applicant_full_name", "Nombre Solicitante");
  columns.add("application_date", "Fecha Solicitud");
  columns.add("status", "Estado Solicitud",{
    filterOperators: getGridSingleSelectOperators(),
    valueOptions: [{value:'pending', label:'Por procesar'},
    {value:5, label:'Completado'},
    {value:8, label:'Entregado'},
    {value:9, label:'No Aplica'},
    {value:2, label:'Cerrados'},
    {value:3, label:'Atrasados'},
    {value:1, label:'En proceso'},
    
  ],
    type: "singleSelect"
  });
  columns.addGetActions((params: any) => [
    <GridActionsCellItem
      icon={<EditIcon />}
      label="Editar"
      onClick={editUser(params.row.id)}
    />,
  ]);

  return (
    <div>
      <StateTickets/>
      <Table columns={columns.getColumn()} targetApi={"tickets"} modeOperand={'server'} />
      <DownloadExcel />
    </div>
  );
}

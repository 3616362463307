import { useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Chart } from "react-google-charts";
import iReport from '../../../interfaces/report.interface';

export default function GraphCrs({report,title}:{report:iReport, title:string[]}) {

  const [formatManager, setFormatManager] = useState<any>(null);

  useEffect(() => {

    const mapped = Object.entries(report.statistics[0].requirement_types).map(([k,v]) =>{

      let nameConstant = {
        'query':'Consultas: '+ v,
        'suggestion':'Sugerencias: '+ v,
        'claim':'Reclamos: '+ v,
        'congrats':'Felicitaciones: '+ v,
      }
       //@ts-ignore
      return [ nameConstant[k] , v ] 
    });
    mapped.unshift(title)

    //@ts-ignore
    setFormatManager(mapped)

  },[report]);


    return (<>
      {formatManager && 
              <Paper>
                <Chart
                   chartType="PieChart"
                  width="75%"
                  height="400px"
                  data={formatManager}
                />
          </Paper>
      }
      </>
  )
}
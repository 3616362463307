import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Formik, Form } from "formik";
import TxtInputMaterial from "../../Components/TxtInputMaterial";
import RadioButton from "../../Components/RadioButton";
import * as Yup from "yup";
import TextArea from "../../Components/TextArea";
import SelectAutocomplete from "../../Components/SelectAutocomplete";

import { Email } from '../../interfaces/email.interface'
import Checkbox from "../../Components/CheckBox";
import { useParams } from "react-router-dom";
//import axios
import iTickets from "../../interfaces/ticket.interface";
import iTicketResponse from "../../interfaces/ticketResponse.interface";
import axios from "../../utils/axios";
import crsjson from "./crs.json";
import areasArray from "./areas.json";
import CrudListEmail from './CrudListEmail';
import { CrsCode } from "../../interfaces/crs_codes.interface";

interface iDepartments {
  txt: string;
  code: "sc" | "mop" | "la" | "scc" | "other" | "";
}
const options = crsjson.crs;

const dictionaryAssigned_department = {
  'sc': 1,
  'mop': 2,
  'la': 3,
  'scc': 4,
  'other': -1,//para que el find de -1
  '': -2//para que el find de -1
}

const listAssigned: iDepartments[] = [
  { txt: "SC / Sociedad Concesionaria", code: "sc" },
  { txt: "MOP / Ministerio Obras Públicas", code: "mop" },
  { txt: "LA / Línea Aérea", code: "la" },
  { txt: "SCC / Sub Concesionario", code: "scc" },
  { txt: "Otro", code: "other" },
];

export default function UpdateTIcket() {
  const { id }: any = useParams();
  const [ticket, setTicket] = useState<iTickets>();
  const [crsCode, setCrsCode] = useState<CrsCode | undefined>();
  const [crsCodeOption, setCrsCodeOption] = useState<{ id: string | undefined, label: string | undefined }>();
  const [area, setArea] = useState<string>('');
  const [image, setImage] = useState(null);
  const [load, setLoad] = useState(true);
  const [idticketResponse, setIdticketResponse] = useState(0);
  const [rowsEmails, setRowsEmails] = useState<Email[] | undefined>();
  const [rowsEmailsAvailables, setRowsEmailsAvailables] = useState<Email[] | undefined>();
  const [crsCodes, setCrsCodes] = useState<CrsCode[]>([]);

  let api =
    process.env.REACT_APP_ENV === "development"
      ? "http://localhost:3000"
      : process.env.REACT_APP_API;
  if (api) {
    api = api.replace('/api/v1/', '')
  } else {
    console.error('api no existe')
  }

  //useEffect get id ticket
  useEffect(() => {
    let opts: any | null = null;
    axios.get("/crs_codes").then((result) => {
      opts = result.data.map((crsCode: { id: string, description: string }) => {
        return { id: crsCode.id, label: crsCode.description };
      })
      setCrsCodes(opts)
    })
    //get axios ticket
    axios.get(`/tickets/${id}`).then((res: any) => {
      const response: iTickets = res.data;
      setArea(response.area);
      if (response?.ticket_response?.id) {
        setIdticketResponse(response.ticket_response.id);
      }

      if (response.ticket_response) {
        const code: CrsCode | undefined = opts.find((c: CrsCode) => response.ticket_response.crs_code_id === c.id) || undefined;
        setCrsCodeOption({ id: code?.id, label: code?.label })
        const getAssigned_department = listAssigned.find(
          (value) => value.txt === response.ticket_response?.assigned_department
        );
        response.ticket_response.crs_code_id = code?.id || "";
        response.ticket_response.assigned_department = getAssigned_department?.code || "";
      }
      setTicket(response);
    });
  }, [id]);

  useEffect(() => {
    axios.get("department_emails").then((result) => {
      setRowsEmails(result.data.department_emails)
      setRowsEmailsAvailables(result.data.department_emails)
    })
  }, [])

  useEffect(() => {
  }, [crsCode, crsCodeOption])

  const handleFileChange = (e: any) => {
    setImage(e.target.files[0]);
  };
  const onSubmit = (values: any) => {
    const ticketResponse: iTicketResponse = values;

    let formData = new FormData();

    if (values.not_apply) {// this checkbox should ignore all the other process 
      formData.append("ticket[status]", "not_apply");
      axios
        .put(`/tickets/${id}`, formData)
        .then((res) => {
          alert("se ingresaron los datos");
        })
        .catch((err) => {
          alert("ocurrio un error");
        });
      return;
    }

    if (image) {
      formData.append("ticket[ticket_response_attributes][file]", image);
    }
    if (!crsCode || !area || !ticketResponse.assigned_department || !values.reply) {
      alert('Todos los campos son obligatorio, por favor revisar')
      return
    }
    if (crsCode) {
      let crsCodeId = crsCode.id;
      if (!crsCode.id) {
        crsCodeId = crsCodes.find(item => item.label === crsCode.toString())?.id || '';
      }
      if (!crsCodeId || crsCodeId === '') {
        alert('No se ha seleccionado un CRS Code válido');
        return
      }
      formData.append(
        "ticket[ticket_response_attributes][crs_code_id]",
        crsCodeId
      );
    }
    else {
      // if no crs code is present, then the request should not be sent
      alert('No se ha seleccionado un CRS Code');
      return
    }
    formData.append("ticket[area]", area);

    formData.append(
      "ticket[ticket_response_attributes][assigned_user]",
      ticketResponse.assigned_user
    );
    formData.append(
      "ticket[ticket_response_attributes][assigned_department]",
      ticketResponse.assigned_department
    );


    if (rowsEmailsAvailables) {
      const filtered: Email[] = rowsEmailsAvailables.filter(
        (val) => val.department_id === dictionaryAssigned_department[ticketResponse.assigned_department] && val.available === true)
      if (filtered.length > 0) {
        for (let i = 0; i < filtered.length; i++) {
          formData.append(
            "ticket[ticket_response_attributes][emails][]",
            filtered[i].email
          );
        }
      } else {
        alert('Debe seleccionar al menos un email para asignar responsables');
        return
      }
    }

    if (idticketResponse) {
      formData.append(
        "ticket[ticket_response_attributes][id]",
        idticketResponse.toString()
      );
    }


    formData.append("ticket[ticket_response_attributes][reply]", values.reply);

    formData.append(
      "ticket[ticket_response_attributes][created_by_id]",
      localStorage.getItem("idUser") || ""
    );

    if (values.status == true) {
      formData.append("ticket[status]", "completed");
    } else {
      formData.append("ticket[status]", "in_process");
    }

    axios
      .put(`/tickets/${id}`, formData)
      .then((res) => {
        alert("se ingresaron los datos");
      })
      .catch((err) => {
        console.error(err);
        alert("ocurrio un error: ");
      });
  };

  const getResult = (value: any) => {
    setCrsCode(value);
  };

  const getResultArea = (value: any) => {
    setArea(value);
  };

  return (
    <div>
      <h1>Detalle de Solicitud</h1>

      {ticket && (
        <table className="customTable">
          <thead>
            <tr>
              <th style={{ width: 200 }}>Campo</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ID</td>
              <td>{ticket.ticket_number}</td>
            </tr>
            <tr>
              <td>Fecha / Hora</td>
              <td>{ticket.application_date}</td>
            </tr>
            <tr>
              <td>Nombre Completo</td>
              <td>{ticket.applicant_full_name}</td>
            </tr>

            <tr>
              <td>Rut o Pasaporte</td>
              <td>{ticket.applicant_identification_number}</td>
            </tr>
            <tr>
              <td>Teléfono</td>
              <td>{ticket.applicant_phone}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{ticket.applicant_email}</td>
            </tr>
            <tr>
              <td>Tipo de requerimiento</td>
              <td>{ticket.requirement_type}</td>
            </tr>
            <tr>
              <td>Descripción</td>
              <td>{ticket.description}</td>
            </tr>
          </tbody>
        </table>
      )}

      <div className="attention">
        <h1>Datos de Atención</h1>
        {ticket && (
          <Card sx={{ minWidth: 275 }} style={{ paddingBottom: 40 }}>
            <Formik
              initialValues={{
                not_apply: ticket?.status == "No Aplica" ? true : false,
                assigned_user: ticket?.ticket_response?.assigned_user || "",
                assigned_department:
                  ticket?.ticket_response?.assigned_department || "",
                reply: ticket?.ticket_response?.reply || "",
                status: ticket?.status == "Completado" ? true : false,
              }}
              onSubmit={(values, { resetForm }) => onSubmit(values)}
            >
              {({ handleChange, values }) => (
                <Form className="form" style={{ padding: 40 }}>
                  <div className="cols">
                    {(
                      <div className="col-md-4 ">
                        <SelectAutocomplete
                          options={crsCodes}
                          label={'CRS:'}
                          initialVal={crsCodeOption?.label}
                          getResult={getResult}
                        />
                      </div>
                    )}
                    <div className="col-md-4">
                      <SelectAutocomplete
                        options={areasArray.areas}
                        label={'Área'}
                        initialVal={ticket?.area || ""}
                        getResult={getResultArea}
                      />
                    </div>
                  </div>
                  <div className="cols">
                    <div className="col-md-12" style={{ marginTop: 20 }}>
                      <div className="form__element">
                        <div className="form__title">Responsables</div>
                        <div className="form__radios materialRadios">
                          <div id='assigned_departmentsc'>
                            <RadioButton
                              name="assigned_department"
                              value="sc"
                              label="SC / Sociedad Concesionaria"
                            />
                            {rowsEmails &&
                              <CrudListEmail id={1} rowsEmails={rowsEmails} setRowsEmailsAvailables={setRowsEmailsAvailables} />}
                          </div>

                          <div id='assigned_departmentmop'>
                            <RadioButton
                              name="assigned_department"
                              value="mop"
                              label="MOP / Ministerio Obras Públicas"
                            />
                            {rowsEmails &&
                              <CrudListEmail id={2} rowsEmails={rowsEmails} setRowsEmailsAvailables={setRowsEmailsAvailables} />
                            }
                          </div>

                          <div id='assigned_departmentmla'>
                            <RadioButton
                              name="assigned_department"
                              value="la"
                              label="LA / Línea Aérea"
                            />
                            {rowsEmails &&
                              <CrudListEmail id={3} rowsEmails={rowsEmails} setRowsEmailsAvailables={setRowsEmailsAvailables} />
                            }
                          </div>
                          <div id='assigned_departmentmlascc'>
                            <RadioButton
                              name="assigned_department"
                              value="scc"
                              label="SCC / Sub Concesionario"
                            />
                            {rowsEmails &&
                              <CrudListEmail id={4} rowsEmails={rowsEmails} setRowsEmailsAvailables={setRowsEmailsAvailables} />
                            }
                          </div>

                          {/* <div id='assigned_departmentmlaotro'>
                            <RadioButton
                              name="assigned_department"
                              value="other"
                              label="Otro"
                            />
                          </div> */}

                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="cols">
                    <div className="col-md-8" style={{ marginTop: 20 }}>
                      <TextArea
                        type="text"
                        name="reply"
                        label="Gestión Realizada"
                        placeholder="Ingrese la gestión realizada"
                      />
                    </div>
                    <div className="col-md-4" style={{ marginTop: 20 }}>
                      <input
                        style={{ position: "initial" }}
                        type="file"
                        name="file"
                        onChange={handleFileChange}
                      ></input>
                      {ticket?.ticket_response?.file && (
                        <a
                          target="_blank"
                          href={
                            api +
                            ticket?.ticket_response?.file
                          }
                          rel="noopener noreferrer"
                        >
                          {" "}
                          Descargar archivo de respaldo{" "}
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="cols">
                    <div className="col-md-6" style={{ marginTop: 20 }}>
                      <div className="form__element">
                        <div className="form__title">Completado</div>
                        <div className="form__radios materialRadios">
                          <Checkbox name="status">Completado</Checkbox>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6" style={{ marginTop: 20 }}>
                      <div className="form__element">
                        <div className="form__title">No aplica</div>
                        <div className="form__radios materialRadios">
                          <Checkbox name="not_apply">No Aplica</Checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cols">
                    <div className="col-md-12" style={{ marginTop: 20 }}>
                      <button type="submit" className="btn btn--small">
                        Enviar solicitud{" "}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        )}
      </div>
    </div>
  );
}

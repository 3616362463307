import React, {useState}  from 'react'
import axios from '../utils/axios'
import { useHistory, Redirect } from "react-router-dom";
import RecoverPass from './RecoverPass';

export default function Index() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  

  function handleSubmit(event:any) {
    event.preventDefault()
    const authData = {
      email,
      password
    };
    axios({
      url: "sessions/sign_in",
      method: "POST",
      data: authData,
    })
      .then((response: any) => {
        
        console.log("response", response);
        localStorage.setItem("accessToken", response.data.jwt);
        localStorage.setItem("user", response.data.first_name);
        localStorage.setItem("rol", response.data.role);
        localStorage.setItem("idUser", response.data.id);

        axios.defaults.headers.common["Authorization"] =
          response.data.jwt;
        axios.defaults.headers.common.Authorization =
          response.data.jwt;
          history.push("/panel");
      })
      .catch((err: any) => {
        alert("Error al inciar sesión, revise sus credenciales");
        console.log("err", err.message);
      });

}

  return (
    <div id="sections">
      <section className="section--form"> 
        <div className="section__logo"> <img src="img/icons/logo-color.png"/></div>
        <div className="section__bg"> 
          <div className="bg"> <img src="img/uploads/2.jpg"/></div>
        </div>
        <div className="section__wrap">
          <div className="section__form section__form--login">
            <h2 className="section__title">Sistema de Gestión CRS</h2>
            <p className="section__description">
            </p>
            <form className="form" onSubmit={handleSubmit}>
              <div className="cols"> 
                <div className="col-xs-12">
                  <div className="form__element"> 
                    <div className="form__title">Email</div>
                    <div className="form__input"> 
                      <input value={email} onChange={(e) => setEmail(e.target.value)}   type="text" name="#"/>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="form__element"> 
                    <div className="form__title">Contraseña</div>
                    <div className="form__input"> 
                      <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="#"/>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="form__buttons form__buttons--login">
                    <button type='submit' className="btn btn--small">Ingresar </button>
                    {/*<a href="reset-password.html" className="btn btn--small btn--colorsecondary">Recuperar contraseña</a>*/}
                  </div>
                </div>
              </div>
            </form>
            <RecoverPass/>
          </div>
        </div>
      </section>
    </div>
  )
}
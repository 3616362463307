import React from "react";
import { Switch, Route, useLocation,Redirect } from "react-router-dom";
import CrsForm from "./PublicViews/CrsForm";
import Login from "./Login/index";
import Dashboard from "./Dashboard/index";
import Panel from "./Dashboard/Panel";
import UpdateTIcket from "./Dashboard/Panel/UpdateTIcket";
import Manage from "./Dashboard/Manage";
import Report from "./Dashboard/Report";
import ConfirmResponsability from "./PublicViews/ConfirmResponsability";

const isAuthenticated = () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    return accessToken;
  } catch (error) {
    return false;
  }
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}

function Routes() {
  const location = useLocation();
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/confirm/attend/:id" component={()=> <ConfirmResponsability/>} />
      <PrivateRoute path={["/panel", "/manage", "/report"]}>
        <Dashboard>
          <Switch location={location} key={location.pathname}>
            <PrivateRoute path="/panel/:id" component={UpdateTIcket} />
            <PrivateRoute path="/panel" component={Panel} />
            <PrivateRoute path="/manage" component={Manage} />
            <PrivateRoute path="/report" component={Report} />
          </Switch>
        </Dashboard>
      </PrivateRoute>
      <Route path="/" component={CrsForm} />
    </Switch>
  );
}

export default Routes;

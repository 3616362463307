import axios from '../utils/axios';
export function loadServerRows(target:string, page: number = 0, limit: number = 5, filter= {}): Promise<any> {
    return new Promise((resolve) => {
      const param = { page, limit, ...filter  }
      axios.get(target,  { params: param}  ).then((res:any) => {
        resolve({
          rows:res.data[target],
          totalCount: res.data.total_pages,
          totalRecords: res.data.total_records || (res.data[target].length * res.data.total_pages)
        });
      });
    });
  }
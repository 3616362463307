import { CreateColumn } from "../interfaces/columns.interface";
import { getGridStringOperators } from "@mui/x-data-grid";
import clsx from 'clsx';
// Factory para crear el JSON de columnas
const createColumn = function (): CreateColumn {
  return {
    columns: [],
    add: function (field: string, headerName: string, args:any) {
      
      this.columns.push({
        field,
        headerName,
        width:200,
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === "contains"
        ),
        ...args,

        
      });

    },
    getColumn: function () {
      return this.columns;
    },
    addGetActions: function (action: Function) {
      this.columns.push({
        field: "actions",
        headerName: "Acciones",
        width: 80,
        type: "actions",
        getActions: action,
      });
    },
  };
};

export default createColumn;

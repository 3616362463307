import { useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Chart } from "react-google-charts";
import iReport from '../../../interfaces/report.interface';
export const options = {
  bars: "horizontal",
  axes: {
    y: {
      0: { side: "left" },
    },
  },
};


export default function GraphManager({report,title}:{report:iReport, title:string[]}) {

  const [formatManager, setFormatManager] = useState<any>(null);

  useEffect(() => {

    const mapped = Object.entries(report.statistics[0].assigned_departments).map(([k,v]) =>{
    
      let nameConstant = {
        '0':'Sociedad Concesionaria',
        '1':'MOP',
        '2':'DGAC',
        '3':'Línea Aérea',
        '4':'Sub Concesionario',
        '5':'otro'
      }
       //@ts-ignore
      return [ nameConstant[k] , v ] 
    });
    mapped.unshift(title)

    //@ts-ignore
    setFormatManager(mapped)

  },[report]);


    return (<>
      {formatManager && 
              <Paper style={{marginTop:10,marginBottom:200}}>
                <Chart
                  chartType="Bar"
                  width="75%"
                  height="300px"
                  data={formatManager}
                  options={options}
                />
          </Paper>
      }
      </>
  )
}
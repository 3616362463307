import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useField } from "formik";
import {useState} from 'react'

export default function PhoneNumber({setPhoneNumber, label, ...props }: any) {
    const [field, meta] = useField(props);
    return (
        <div className="form__element">
        <div className="form__title">{label}
            {meta.touched && meta.error ? (
            <label className="error">{meta.error}</label>
          ) : null} </div>
        <div className="form__input phone">
            <PhoneInput
            
                country={'cl'}
                {...field} {...props}
                onChange={ (e)=>{ setPhoneNumber(field.name, e) }}
            />
        </div>
      </div>
  )
}
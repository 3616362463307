import React from 'react'
import Swal from 'sweetalert2'

export default function RecoverPass() {

  const api =
    process.env.REACT_APP_ENV === "development"
      ? "http://localhost:3000/api/v1"
      : process.env.REACT_APP_API;

  const sweetPass = () => {
    Swal.fire({
      title: 'Ingresar Correo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return fetch(`${api}users/password_recovery?email=${login}`)
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Revise su bandeja de entrada`,
        })
      }
    })
  }

  return (
    <div onClick={sweetPass}
      style={{
        display: 'inline-flex',
        color: 'blue',
        cursor: 'pointer'
      }}>Recuperar Contraseña</div>
  )
}

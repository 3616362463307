import { useState, ChangeEvent, useEffect } from 'react'
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import axios from '../../utils/axios'
import { Email } from '../../interfaces/email.interface'

const columns: GridColDef[] = [
  { field: 'email', headerName: 'email', width: 200 },
];

export default function CrudListEmail({ id, rowsEmails, setRowsEmailsAvailables }: 
  { id: number, rowsEmails: Email[] , setRowsEmailsAvailables?:any }) {

  const [email, setEmail] = useState<string>('');
  const [rowsFiltered, setRowsFiltered] = useState<Email[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);


  const hanldeEmail = (val: ChangeEvent<HTMLInputElement>) => {
    setEmail(val.target.value);
  }

  useEffect(() => {
    const filtered = rowsEmails.filter((val) => val.department_id === id)
    setRowsFiltered(filtered)
  }, [rowsEmails])

  const postEmail = (e: any) => {
    e.preventDefault()
    const formData = new FormData();
    if (email && email.includes('@')) {
      formData.append('department_email[email]', email)
      formData.append('department_email[department_id]', id.toString())
      formData.append('department_email[enabled]', id.toString())
      axios.post('./department_emails/', formData).then((res) => {
        if (res.status === 200) {
          const newData: Email = res.data
          setRowsFiltered(prev => ([...prev, newData]))
          setEmail('')
          alert('correo ingresado')
        } else {

        }
      })
    } else {
      alert('debes añadir un email')
    }
  }

  return (
    <div id={id.toString()}>
      <div className="row-inline">
        <input
          value={email}
          style={{ position: "initial" }}
          type="email"
          onChange={(e) => hanldeEmail(e)}
        ></input>
        <button onClick={(e) => postEmail(e)} className="btn btn--small"> +</button>
      </div>

      {rowsFiltered.length &&
        <DataGrid
          style={{ height: 217, width: '100%' }}
          rows={rowsFiltered}
          columns={columns}
          hideFooter={true}
          hideFooterSelectedRowCount={true}
          checkboxSelection={true}
          onSelectionModelChange={(newSelectionModel) => {
            const rowsFilteredWithAvailables = rowsFiltered.map(row => {
              if( newSelectionModel.includes(row.id)){
                row.available = true;
                return row
              }else{
                if(row.available && row.department_id !== id ){

                }else{
                  row.available = false;
                }
                return row
              }
            } )

            setRowsEmailsAvailables((prev:any) => { return rowsFilteredWithAvailables  })
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      }
    </div>
  )
}

import React, { useRef } from 'react';
import AreaReport from "./AreaReport/AreaReport";
import CRSReport from "./TotalReport/CRSReport";
import ManagerReport from "./ManagerReport/ManagerReport";
import { useReactToPrint } from 'react-to-print';
import { Card } from '@mui/material';

export default function Report() {

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div ref={componentRef} >
          <AreaReport /> 
          <ManagerReport />
          <CRSReport />
      </div>
      <br></br><br></br>
      <button onClick={()=>{  window.print()}}  className="btn btn--small noprint">Pasar a PDF esta ventana</button>
    </>
  );
}

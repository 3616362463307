import React from "react";
//import button material ui
import Button from "@mui/material/Button";
//import axios
import axios from "../../utils/axios";
import { LensTwoTone } from "@mui/icons-material";
export default function DownloadExcel() {
  const downloadExcel = () => {
    axios.get("/reports/ticket_report").then((res) => {
      

      let api =
        process.env.REACT_APP_ENV === "development"
          ? "http://localhost:3000"
          : process.env.REACT_APP_API ;
          if(api){
            api = api.replace('/api/v1/','')
            const url = `${api}${res.data.file}`;
            window.open(url);
          }else{
            console.error('api no existe')
          }
    });
  };

  return (
    <div>
      <Button
        onClick={() => downloadExcel()}
        style={{ marginTop: 20 }}
        variant="contained"
      >
        Descargar Excel
      </Button>
    </div>
  );
}

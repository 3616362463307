import { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormLabel from '@mui/material/FormLabel';
import FormControl from "@mui/material/FormControl";
import DateForm from "../DateForm";
import Graph from "./Graph"
import iReport from '../../../interfaces/report.interface';
import axios from "../../../utils/axios"
import useMsgFetch from '../../../hooks/useMsgFetch'

export default function AreaReport() {
  const [datos, setDatos] = useState({
    crs: "",
    area: "",
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [report, setReport ]= useState<iReport | null>(null);

  const statusApi = useMsgFetch( (query:string) => 
  axios.get(query)
  .then((response:any) => response) );

  const handleInputChange = (event: any) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value
    })
  }

  const onSubmit = async () => {

    if(!startDate || !endDate || !datos.crs ){
      alert('Completar campo fecha y filtro por crs ')
      return false
    }

    let dataStartDate ='';
    let dataEndDate = '';
    if(startDate?.getMonth() && endDate?.getMonth()){
        dataStartDate =  `start_date=${startDate?.getFullYear()}/${startDate?.getMonth() + 1}/${startDate?.getDate()}&`
        dataEndDate =  `end_date=${endDate?.getFullYear()}/${endDate?.getMonth() + 1}/${endDate?.getDate()}&`
    }
    const crs = datos?.crs !== 'Todas' ? `requirement_type=${datos.crs}&` : ''
    const query = `/tickets/statistics?`+
    `by_area=1&`+
    dataStartDate+
    dataEndDate+
    crs+
 //   `by_responsible=''&`+
    `by_requirement_type=''`

    const result = await statusApi.run(query);

    if( Object.keys(result.statistics[0].areas).length === 0){
     alert('No hay registros en estas fechas')
     setReport(null)
   }else{
     setReport(result)
   }

  }

  return (
    <div  style={{ marginTop:20}}>
      <Box sx={{ minWidth: 120 }}>
       
       <h1>Reporte Area</h1>
       <div style={{ display: 'flex', flexDirection: 'row', width:'100%'}}>
       <FormControl >
          <FormLabel id="demo-controlled-radio-buttons-group">Periodo de análisis</FormLabel>
          <div style={{ marginTop:20, marginBottom:20, display: 'flex'}}>
            <DateForm  txt={'Fecha Inicio'} startDate={startDate} setStartDate={setStartDate} />
            <DateForm txt={'Fecha Final'} startDate={endDate} setStartDate={setEndDate} />
          </div>
        </FormControl>
     
        <FormControl style={{ marginTop:42, marginBottom:10,marginLeft:10 }}>
          <InputLabel id="demo-simple-select-label">Tipo de CRS</InputLabel>
          <Select
          style={{width:200}}
            name='crs'
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={datos['crs']}
            label="CRS"
            onChange={handleInputChange}
          >
            <MenuItem value={'query'}>Consultas</MenuItem>
            <MenuItem value={'claim'}>Reclamos</MenuItem>
            <MenuItem value={'suggestion'}>Sugerencias</MenuItem>
            <MenuItem value={'congrats'}>Felicitaciones</MenuItem>
            <MenuItem value={'Todas'}>Todas</MenuItem>
          </Select>
        </FormControl>
        </div>
        <button  className="btn btn--small noprint"  onClick={()=>onSubmit()}> {statusApi.loading ? 'Cargando': 'Buscar '}</button>
      </Box>
            {report && <Graph report={report} title={['Área','Tickets']} /> }
    </div>
  );
}
